
var miwuki = function()
{
    const poliza = {
        id: null,
        modalidad: null,
        pago: null,
        especie: null,
        raza: null,
        fecha_nac: null,
        res_civil: null,
        segunda: null,
        dni: null
    }

    const polizaItems = {
        modalidad: document.getElementById('modalidad'),
        pago: document.getElementById('forma_pago'),
        especie: document.getElementById('especie'),
        raza: document.getElementById('raza'),
        resCivil: document.getElementById('res_civil'),
        fechaNac: document.getElementById('mascota_fecha'),
        dni: document.getElementById('dni'),
    }

    const tomadorItems = {
        section: document.getElementById('tmdr-section'),
        nombre: document.getElementById('tmdr-nombre'),
        email: document.getElementById('tmdr-email'),
        error: document.getElementById('tmdr-error'),
        data: document.getElementById('tmdr-data'),
        mascotas: document.getElementById('tmdr-mascotas'),
        fechaNac: document.getElementById('tomador_fecha'),
        fechaEfecto: document.getElementById('fecha_efecto')
    }

    const formItems = {
        form: document.getElementById('form-contratacion'),
        submitBtn: document.getElementById('btn_submit'),
        confirmarTomador: document.getElementById('btn-tomador'),
        buscarDni: document.getElementById('btn-dni'),
    }

    const stepItems = {
        step2: document.getElementById('step2'),
        step3: document.getElementById('step3'),
        step4: document.getElementById('step4')
    }

    const infoItems = {
        planAmigo: document.getElementById('mw-plan-amigo'),
        asistVet: document.getElementById('mw-info-1'),
        indmFall: document.getElementById('mw-info-2'),
        resCivil: document.getElementById('mw-res-civil'),
        infoResCivil: document.getElementById('mw-info-3'),
        segundaMascota: document.getElementById('mw-info-4'),
        descuento: document.getElementById('mw-info-5'),
        infoPago: document.getElementById('mw-info-pago'),
        infoPeriodo: document.getElementById('mw-info-periodo'),
        infoDescuento: document.getElementById('mw-info-desc'),
        infoMes: document.querySelectorAll('.mw-info-mes'),
        modalidad: document.getElementById('mw-modalidad'),
        tarifa: document.getElementById('mw-tarifa'),
        subtotal: document.getElementById('mw-subtotal'),
        total: document.getElementById('mw-total')
    }

    var init = function () {

        var polizaItemsList = generateList(polizaItems)
        setDates()

        if(submitted === true) {
            poliza.id = null,
            poliza.modalidad = polizaItems.modalidad.value,
            poliza.pago = (polizaItems.modalidad.value === 'RC') ? 'A' : polizaItems.pago.value,
            poliza.especie = (polizaItems.modalidad.value === 'RC') ? 'P' : polizaItems.especie.value,
            poliza.raza = (/^-?\d+$/.test(raza)) ? parseInt(raza) : null,
            poliza.fecha_nac = (polizaItems.fechaNac.value === "") ? null : polizaItems.fechaNac.value,
            poliza.res_civil = (polizaItems.modalidad.value === 'RC') ? false : polizaItems.resCivil.checked,
            poliza.segunda = (polizaItems.modalidad.value === 'RC') ? false : ((polizaItems.dni.value !== '') ? true : false),
            poliza.dni = polizaItems.dni.value

            console.log(poliza)

            getRazas()
            updatePoliza()
            showStep(stepItems.step4, true)
        }

        // Botones de modalidad
        document.querySelectorAll('.mw-btn-m').forEach(item => {
            item.addEventListener('click', e => {
                let m = item.getAttribute('data-modalidad')
                setModalidad(m)
                showStep(stepItems.step2, true)
                showStep(stepItems.step3)
                showStep(stepItems.step4)
            })
        })

        // Botones de tomador (Step2)
        document.getElementById('mw-btn-pm').addEventListener('click', e => {
            setSegundaMascota(false)
            showStep(stepItems.step2)
            showStep(stepItems.step3)
            showStep(stepItems.step4, true)
        })

        document.getElementById('mw-btn-sm').addEventListener('click', e => {
            showStep(stepItems.step2)
            showStep(stepItems.step3, true)
            showStep(stepItems.step4)
        })

        // Busqueda de tomador (Step3)
        formItems.buscarDni.addEventListener('click', e => {
            getTomadorData(document.getElementById('dni').value)
        })

        // Confirmar tomador
        formItems.confirmarTomador.addEventListener('click', e => {
            setSegundaMascota(true, poliza.dni)
            showStep(stepItems.step2)
            showStep(stepItems.step3)
            showStep(stepItems.step4, true)
        });

        // Evento cambio poliza
        polizaItemsList.forEach(function(item) {
            item.input.addEventListener('change', function() {
                let value = item.input.value
                switch (item.name) {
                    case 'modalidad': setModalidad(value); break;
                    case 'pago': setPago(value); break;
                    case 'especie': setEspecie(value); getRazas(); break;
                    case 'raza': setRaza(value); break;
                    case 'resCivil': setResCivil(item.input.checked); break;
                    case 'fechaNac': setFechaNac(value); break;
                }
                showInfoPago()
                updatePoliza()
            });
        });

        // Submit button
        formItems.submitBtn.addEventListener('click', e => {
            formItems.submitBtn.disabled = true
            formItems.submitBtn.querySelectorAll('.spinner-border').forEach(e => e.classList.remove('d-none'))
            formItems.submitBtn.querySelectorAll('.btn-text').forEach(e => e.classList.add('d-none'))
            formItems.form.submit()
        });

        function updatePoliza() {
            if([poliza.modalidad, poliza.pago, poliza.especie, poliza.raza, poliza.fecha_nac].some(v => !v)) {}
            else getPolizaInfo()
        }

        var setModalidad = function (modalidad) {
            poliza.modalidad = modalidad
            polizaItems.modalidad.value = poliza.modalidad

            if(poliza.modalidad === 'RC') {
                setEspecie('P', true)
                setPago('A', true)
            }
            else {
                setEspecie(poliza.especie, false)
                setPago(null, false)
            }
            setDates()
            getRazas()
            showInfoPago()
        }

        var setPago = function (pago, disabled = false) {
            poliza.pago = pago
            if(pago === null) polizaItems.pago.selectedIndex = 0
            else polizaItems.pago.value = pago
            polizaItems.pago.disabled = disabled
        }

        var setEspecie = function (especie, disabled = false) {
            poliza.especie = especie
            if(especie === null) polizaItems.especie.selectedIndex = 0
            else polizaItems.especie.value = especie
            polizaItems.especie.disabled = disabled
        }

        var setRaza = function (raza, disabled = false) {
            poliza.raza = raza
            polizaItems.raza.disabled = disabled
        }

        var setResCivil = function (resCivil) {
            poliza.res_civil = resCivil
            polizaItems.resCivil.checked = resCivil
        }

        var setFechaNac = function (fechaNac) {
            poliza.fecha_nac = fechaNac
        }

        var setSegundaMascota = function (segunda, dni = null) {
            poliza.segunda = (poliza.modalidad !== 'RC') ? segunda : false
            poliza.dni = (segunda === false) ? null : dni
            if(segunda === true) {
                tomadorItems.section.classList.add('d-none')

                var i = document.createElement('input');
                i.setAttribute('id', 'tmdr_dni');
                i.setAttribute('name', 'tmdr_dni');
                i.setAttribute('value', dni);
                i.setAttribute('type', 'hidden');
                document.getElementById('tmdr-section').appendChild(i);
            }
            else tomadorItems.section.classList.remove('d-none')
        }

        function setDates() {
            var mascota_min = new Date(
                new Date().getFullYear() - 9,
                new Date().getMonth(),
                new Date().getDate()
            )

            var mascota_max = new Date(
                new Date().getFullYear(),
                new Date().getMonth() - 3,
                new Date().getDate()
            )

            var tomador_max = new Date(
                new Date().getFullYear() - 18,
                new Date().getMonth(),
                new Date().getDate()
            )

            if(poliza.modalidad === 'RC') {
                polizaItems.fechaNac.min = null
                polizaItems.fechaNac.max = new Date().toISOString().split("T")[0]
            } else {
                polizaItems.fechaNac.min = mascota_min.toISOString().split("T")[0]
                polizaItems.fechaNac.max = mascota_max.toISOString().split("T")[0]
            }
            tomadorItems.fechaNac.max = tomador_max.toISOString().split("T")[0]

            var fecha_actual = new Date();
            var fecha_objetivo = new Date('2023-09-28');
            if (fecha_actual < fecha_objetivo) {
                var fecha_efecto_max = new Date('2023-09-28');
                tomadorItems.fechaEfecto.max = fecha_efecto_max.toISOString().split("T")[0];
            }
            tomadorItems.fechaEfecto.min = new Date().toISOString().split("T")[0];
        }

        function showInfoPago(info) {
            if(poliza.modalidad === 'CO')
                infoItems.planAmigo.classList.remove('d-none')
            else
                infoItems.planAmigo.classList.add('d-none')

            if(poliza.modalidad === 'RC') {
                infoItems.resCivil.classList.add('d-none')
                infoItems.infoResCivil.classList.add('d-none')
                infoItems.asistVet.classList.add('d-none')
                infoItems.indmFall.classList.add('d-none')
                infoItems.segundaMascota.classList.add('d-none')
            }
            else{
                infoItems.resCivil.classList.remove('d-none')
                infoItems.asistVet.classList.remove('d-none')
                infoItems.indmFall.classList.remove('d-none')
                if(poliza.segunda === true) infoItems.segundaMascota.classList.remove('d-none')
                else infoItems.segundaMascota.classList.add('d-none')
                if(polizaItems.resCivil.checked === true) infoItems.infoResCivil.classList.remove('d-none');
                else infoItems.infoResCivil.classList.add('d-none');
            }

            infoItems.descuento.classList.add('d-none');
            let mes = '';
            let tarifa = (info) ? formatPrecio(info.precio) : '—';
            switch (poliza.pago) {
                case 'M':
                    infoItems.infoPago.innerHTML = "*Se hará un pago <strong>mensual</strong> de <strong>"+tarifa+"€</strong>";
                    infoItems.infoDescuento.innerHTML = "—";
                    mes = "mes";
                    break;
                case 'T':
                    infoItems.infoPago.innerHTML = "*Se hará un pago de <strong>"+tarifa+"€</strong> cada <strong>3 meses</strong>";
                    infoItems.infoDescuento.innerHTML = "-2%";
                    mes = "3 meses";
                    if(poliza.modalidad !== 'RC') infoItems.descuento.classList.remove('d-none');
                    break;
                case 'S':
                    infoItems.infoPago.innerHTML = "*Se hará un pago de <strong>"+tarifa+"€</strong> cada <strong>6 meses</strong>";
                    infoItems.infoDescuento.innerHTML = "-3%";
                    mes = "6 meses";
                    if(poliza.modalidad !== 'RC') infoItems.descuento.classList.remove('d-none');
                    break;
                case 'A':
                    infoItems.infoDescuento.innerHTML = "-5%";
                    infoItems.infoPago.innerHTML = "*Se hará un único pago de <strong>"+tarifa+"€</strong> al año";
                    mes = "año";
                    if(poliza.modalidad !== 'RC') infoItems.descuento.classList.remove('d-none');
                    break;
            }

            infoItems.infoMes.forEach(item => {
                item.innerHTML = mes;
            });

            infoItems.infoPeriodo.innerHTML = (info) ? getPagoLabel(poliza.pago) : '—'

            infoItems.infoPago.parentElement.classList.remove('d-none');

            if(info) {
                infoItems.subtotal.innerHTML = formatPrecio(subtotal(info.real));
                infoItems.total.innerHTML = formatPrecio(info.precio);
            }
        }

        var subtotal = function (num) {
            switch (poliza.pago) {
                case 'M': return num/11;
                case 'T': return num/4;
                case 'S': return num/2;
                default:  return num;
            }
        }

        var getPagoLabel = function (pago) {
            switch (pago) {
                case 'M': return 'Mensual';
                case 'T': return 'Trimestral';
                case 'S': return 'Semestral';
                default:  return 'Anual';
            }
        }

        var formatPrecio = function (num) {
            return (num.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'))
        }

        function getPolizaInfo() {
            let status = null
            fetch('/info/poliza', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    c: poliza.res_civil,
                    e: poliza.especie,
                    f: poliza.fecha_nac,
                    m: poliza.modalidad,
                    p: poliza.pago,
                    r: poliza.raza,
                    s: poliza.segunda
                })
            })
            .then(function(response) {
                status = response.status
                return response.json()
            })
            .then(function (res) {
                if(status === 200) {
                    let info = res.response.data.seguro;
                    if(info) showInfoPago(info);
                }
            })
        }

        function getTomadorData(dni) {
            let status = null
            fetch('/info/tomador', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    dni: dni,
                })
            })
            .then(function(response) {
                status = response.status
                return response.json()
            })
            .then(function (res) {
                if(status === 200) {
                    let tomador = res.response.data;
                    if(tomador) {
                        tomadorItems.nombre.innerHTML = tomador.nombre
                        tomadorItems.email.innerHTML = tomador.email
                        tomadorItems.error.classList.add('d-none')
                        tomadorItems.data.classList.remove('d-none')
                        tomadorItems.mascotas.innerHTML = ''
                        poliza.dni = dni

                        tomador.mascotas.forEach(m => {
                            let li = document.createElement("li")
                            li.innerHTML = m
                            tomadorItems.mascotas.appendChild(li)
                        });
                    }
                    else {
                        tomadorItems.data.classList.add('d-none')
                        tomadorItems.error.classList.remove('d-none')
                    }
                }
                if(status === 422) {
                    tomadorItems.data.classList.add('d-none')
                    tomadorItems.error.classList.remove('d-none')
                }
            });
        }

        function getRazas() {
            if(poliza.especie === null) return
            fetch('/info/razas', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    e: poliza.especie,
                })
            }).then(res => res.json())
            .then(function (res) {
                const select = polizaItems.raza
                select.disabled = true
                select.innerHTML = ""
                var razas = res.response.data.razas
                var o = new Option('—', null)
                o.disabled = true
                o.selected = true
                select.add(o)
                razas.forEach(r => select.add(new Option(r.nombre, r.id, null, r.id === poliza.raza)))
                select.disabled = false
            });
        }

        function showStep(step, show = false) {
            if (show) step.classList.remove('d-none')
            else step.classList.add('d-none')
            if(show === true) scrollTo(step)
        }

        function generateList(items) {
            const list = [];
            for (const key in items) {
                if (items.hasOwnProperty(key)) list.push({ name: key, input: items[key] })
            }
            return list
        }

        function scrollTo(step) {
            step.scrollIntoView()
        }
    }

    return {
        init: init,
    };
}();

export { miwuki };
window.miwuki = miwuki;
